.segmented-control {
    display: flex;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .segmented-control button {
    flex: 1;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
  }
  
  .segmented-control button:hover {
    background-color: #ddd;
  }
  
  .segmented-control button.active {
    background-color: rgb(247, 51, 152);
    color: #fff;
  }
  