
.card {
    background-color: white;
    box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.9);
    border-radius: 10px;
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
 
    max-width: 1024px;
    overflow: hidden;
    flex-direction: column;
    position: relative;
}



@media (max-width: 768px) {
    .card {
        margin-left: 10px;
        margin-right: 10px;
        margin-top:15px;
    }
}

.pulsate-emoji {
    display: inline-block;
    animation: pulsate 1.5s infinite;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

