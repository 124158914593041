@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  font-family: 'Poppins', sans-serif;
}

.header-2 {
  text-align: center;
  color: white;
  font-size: 1rem;
}

.header-2-black {
  text-align: center;
  color: rgb(32, 32, 32);
  font-size: 1.2rem;
}

.header-2-left {
  text-align: left;
  color: white;
  font-size: 1rem;
}

.subheader-2 {
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.light-turquoise-bg {
  background-color: #28ADBE;
}

.dark-turquoise-bg {
  background-color: #1F8199;
}

.light-turquoise-text {
  color: #28ADBE;
}

.dark-turquoise-text {
  color: #1F8199;
}

.navy-bg {
  background-color: #0d203f;
}

.lightgrey-bg {
  background-color: #f5f5f5;
}

.graphite-bg {
  background-color: #546974;
  background-image: radial-gradient(#5f7581 10%, transparent 10%);
  background-size: 20px 20px;
  background-position: 0 0, 0px 0px;
  background-attachment: fixed;
}

.bigger-body-text {
  color: white;
}

.pinkButton {

  color: white;
  border-radius: 9999px;
  width: fit-content;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 0.875rem;
 
}

.pinkButton:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.125);
  transform: scale(1.01);
}

.leftBracket {
  width: 1.5rem;
  height: 100%;
  margin-right: 1.5rem;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.rightBracket {
  width: 1.5rem;
  margin-right: 1.5rem;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
